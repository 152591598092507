import React from "react"
import { Helmet } from "react-helmet"
import { Loading, GuildWidget } from "pages/components"
import { GuildInfo } from "types"

import "css/style-mapperbot.css"
import { PixelAPI } from "modules"

interface MapperBotProps {
}
interface MapperBotState {
    guilds? : GuildInfo[]
}
class MapperBot extends React.Component<MapperBotProps, MapperBotState> {
    render() {
        return (
            <>
            <Helmet>
                <title>Pixelguy - Mapperbot</title>
                <meta property="og:title" content="Pixelguy - Mapperbot"/>
            </Helmet>
            <div id="guild-container">
                { this.getGuildWidgets() }
            </div>
            <div className="centered">
                <button className="rounded-button" onClick={() => this.invite()}>
                    Invite MapperBot
                </button>
            </div>
            </>
        )
    }

    componentDidMount() {
        this.getGuilds().then((guilds : GuildInfo[]) => {
            this.setState({
                guilds: guilds
            })
        })
    }

    async getGuilds() {
        let guildIndex = await PixelAPI.getGuilds().then(res => res.succeeded ? res.data : [])
        let guilds : GuildInfo[] = []

        for (let guild of guildIndex) {
            const macros = await PixelAPI.getMacros(guild.id, "name").then(res => res.succeeded ? res.data.length : 0)
            const packs = await PixelAPI.getPacks(guild.id, "name").then(res => res.succeeded ? res.data.length : 0)

            guilds.push({
                id: guild.id,
                name: guild.name,
                icon: guild.icon,
                macros: macros,
                packs: packs
            })
        }

        return guilds
    }

    getGuildWidgets() {
        if (this.state?.guilds === undefined) {
            return (<Loading />)
        }
        if (this.state?.guilds.length === 0) {
            return (
                <div className="centered">
                    <h2>Can't find any guilds.</h2>
                    <p>Please log in with your Discord account, or invite MapperBot to one of your guilds.</p>
                </div>
            )
        }

        let widgets : React.ReactElement[] = []
        for (let i in this.state.guilds) {
            let guild = this.state.guilds[i]
            widgets.push(
                <GuildWidget 
                    key = { i }
                    id = { guild.id } 
                    name = { guild.name } 
                    image = { `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp?size=128` } 
                    macroCount = { guild.macros } 
                    packCount = { guild.packs }
                />
            )
        }

        return widgets
    }

    invite() {
        window.location.href = "https://discord.com/oauth2/authorize?client_id=701875159673471026&scope=bot&permissions=275683535938"
    }
}

export { MapperBot }