function Footer() {
    return (
        <div id="footer">
            <h3>Check Out My Socials!</h3>
            <div id="socials">
                <div className="social-block">
                    <a className="social-link" href="https://beatsaver.com/profile/username/pixelguy">Beat Saver</a>
                    <a className="social-link" href="https://bsaber.com/members/Pixelguy/">BeastSaber</a>
                    <a className="social-link" href="https://scoresaber.com/u/76561198976682243">ScoreSaber</a>
                </div>
                <div className="social-block">
                    <a className="social-link" href="https://twitter.com/pixelguymm">Twitter</a>
                    <a className="social-link" href="https://bsky.app/profile/pixelguy.me">Bluesky</a>
                    <a className="social-link" href="https://www.youtube.com/@Pixelguymm">YouTube</a>
                    <a className="social-link"
                       href="https://steamcommunity.com/profiles/76561198976682243/">Steam</a>
                </div>
                <div className="social-block">
                    <a className="social-link" href="https://github.com/Pixelguymm">Github</a>
                    <a className="social-link" href="https://patreon.com/pixelguy/">Support Me</a>
                </div>
            </div>
        </div>
    )
}

export {Footer}