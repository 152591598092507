import React from "react"
import { Helmet } from "react-helmet"
import { Loading, PackWidget, Dropdown } from "pages/components"
import { GuildInfo, UserData } from "types"
import { Alert } from "context"

import "css/style-packs.css"
import "css/style-discord.css"
import { PixelAPI, StorageReturnType, StorageTool } from "modules"

interface PacksProps {
    guildId : string
}
interface PacksState {
    order: string;
    apiAccess?: any | null
    packs?: Array<any> | null,
    guild?: GuildInfo | null
}
class Packs extends React.Component<PacksProps, PacksState> {
    static contextType = Alert
    state : PacksState = { order: "Name" }

    async componentDidMount() {
        let guilds = await PixelAPI.getGuilds().then(res => res.succeeded ? res.data : []) as Array<GuildInfo>
        let guild = guilds.find(g => g.id === this.props.guildId)
        let access = await PixelAPI.getAccess().then(res => res.data)
        let packs = await PixelAPI.getPacks(this.props.guildId, this.state.order.toLowerCase()).then(res => res.data) as Array<any>

        this.setState({
            apiAccess: access,
            packs: packs,
            guild: guild || null
        })
    }

    render() {
        if (this.state.guild === undefined) return <Loading />
        if (this.state.guild == null) return (
            <>
                <Helmet>
                    <title>Pixelguy - Packs</title>
                    <meta property="og:title" content="Pixelguy - Packs"/>
                </Helmet>
                <div className="centered">
                    <h2>The requested guild can not be found.</h2>
                    <p>Please go back to the <a href="/mapperbot">previous page</a> and select a guild from there.</p>
                </div>
            </>
        )

        return (
            <>
                <Helmet>
                    <title>Pixelguy - Packs</title>
                    <meta property="og:title" content="Pixelguy - Packs"/>
                </Helmet>
                <div className="centered">
                    <div id="center">
                        <div id="guild-title">
                            <img src={`https://cdn.discordapp.com/icons/${this.props.guildId}/${this.state.guild.icon}.webp?size=128`} alt="" className="guild-image" />
                            <h1>{this.state.guild.name}</h1>
                        </div>
                        <div className="filter-sort-options">
                            <p>Order by:</p>
                            <Dropdown text={this.state.order}>
                                <button className="dropdown-item" onClick={() => this.changeOrder("Name")}>Name</button>
                                <button className="dropdown-item" onClick={() => this.changeOrder("Date")}>Date</button>
                            </Dropdown>
                        </div>
                        <div id="packs-container">
                            {this.getPackWidgets()}
                        </div>
                        <button className="add-button" title="Add a Pack" onClick={() => this.handleAdd()}><i className="fas fa-plus"></i></button>
                    </div>
                </div>
            </>
        )
    }

    getPackWidgets() {
        if (this.state?.packs === undefined) {
            return (<Loading />)
        }
        if (this.state?.packs == null) {
            return (
                <div className="centered">
                    <h2>Can't find any packs.</h2>
                </div>
            )
        }

        let widgets : React.ReactElement[] = []
        for (let pack of this.state.packs) {
            widgets.push(
                <PackWidget 
                    key = { pack._id }
                    id = { pack._id }
                    deleteAccess = { this.state.apiAccess?.administrator || this.state.apiAccess.userId === pack.creatorId}
                    handleDelete = { (id : string) => this.handleDelete(id) }
                    handleSave = { ( id : string, pack : any ) => this.handleSave(id, pack) }
                    name = { pack.name }
                    deadline = { pack.deadline }
                    requirements = { pack.requirements }
                    creatorId = { pack.creatorId }
                    guildId = { this.props.guildId }
                />
            )
        }

        return widgets
    }

    async handleDelete(id : string) {
        let res = await PixelAPI.deletePack(id)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to delete this pack.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to delete this pack.")
            return
        }

        let packs = await PixelAPI.getPacks(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            packs: packs
        })
    }

    async handleAdd() {
        let userData : UserData = StorageTool.getSession("user-data", StorageReturnType.json)
        let data = { name: "New Pack", guildId: this.props.guildId, creatorId: userData.id }

        let res = await PixelAPI.postPack(data)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to add new packs.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to add a new.")
            return
        }

        let packs = await PixelAPI.getPacks(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            packs: packs
        })
    }

    async handleSave(id : string, pack : any) {
        let res = await PixelAPI.putPack(id, pack)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to edit packs.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to edit this pack.")
            return
        }

        let packs = await PixelAPI.getPacks(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            packs: packs
        })
    }

    async changeOrder(order: string) {
        let packs = await PixelAPI.getPacks(this.props.guildId, order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            order: order,
            packs: packs
        })
    }
}

export { Packs }