import React from "react"
import { Login } from "pages/components"
import { StorageTool, StorageReturnType } from "modules"

interface ToolbarProps {
}
interface ToolbarState {
    themeIndex : number,
    collapsed : boolean
}
class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
    state : ToolbarState = {
        themeIndex: 0,
        collapsed: true
    }

    themes : string[] = [
        "theme-dark",
        "theme-light"
    ]

    componentDidMount() {
        let themeIndex : number = StorageTool.getLocal("theme", StorageReturnType.number) || 0
        if (themeIndex < 0 || themeIndex >= this.themes.length) themeIndex = 0
        document.body.className = this.themes[themeIndex]
        this.setState({
            themeIndex: themeIndex
        })
    }

    handleThemeChange() {
        let nextTheme : number
        if (this.state.themeIndex === this.themes.length - 1) nextTheme = 0
        else nextTheme = this.state.themeIndex + 1

        document.body.className = this.themes[nextTheme]

        StorageTool.setLocal("theme", nextTheme)

        this.setState({
            themeIndex: nextTheme
        })
    }

    render() {
        return (
            <div id="toolbar">
                <nav id="navbar" className={this.state.collapsed ? "collapsed" : ""}>
                    <div className="navbar-element">
                        <i className="fas fa-angle-down collapse-indicator hidden" onClick={() => this.handleCollapse()}></i>
                        <a href="/">
                            <em>Pixelguy.me</em>
                        </a>
                    </div>
                    <div className="navbar-element">
                        <a href="/commissions">
                            Commissions
                        </a>
                    </div>
                    <div className="navbar-element">
                        <a href="/mapperbot">
                            MapperBot
                        </a>
                    </div>
                </nav>
    
                <div id="settings">
                    <div className="settings-element" id="theme-selector" onClick={() => this.handleThemeChange()}>
                        <div id="theme-color"></div>
                        <p id="theme-text">Theme</p>
                    </div>
                    <Login />
                </div>
            </div>
        )
    }

    handleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }
}

export { Toolbar }