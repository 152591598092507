class WebTool {
    static parseURL(href: string) {
        let urlData : any = {}

        if (!href.includes("?")) {
            return urlData
        }

        const urlParams : string[] = href.split("?")[1].split("&")
        urlParams.forEach((i : string) => {
            if (!i.includes("=")) return

            let [key, value] = i.split("=")
            urlData[key] = value
        })

        return urlData
    }

    static getURLparam(name : string) {
        let href = window.location.href
        let urlData = this.parseURL(href)
        return urlData[name]
    }
}

export { WebTool }