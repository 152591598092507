import React from "react"
import { Discord } from "pages/components"

interface MacroWidgetProps {
    key: string,
    id: string,
    writeAccess: boolean
    handleDelete: (id : string) => void,
    handleSave: (id : string, macro : any) => void,
    name: string,
    aliases: string[],
    text: string,
    embed?: {
        title?: string | null,
        description?: string | null,
        color?: string | null
    }
    adminOnly: boolean
}
interface MacroWidgetState {
    collapsed: boolean,
    editing: boolean,
    name: string,
    aliases: string[]
    text: string,
    embed?: {
        title?: string | null,
        description?: string | null,
        color?: string | null
    }
    adminOnly: boolean
}

class MacroWidget extends React.Component<MacroWidgetProps, MacroWidgetState> {
    state : MacroWidgetState = {
        collapsed: true,
        editing: false,
        name: this.props.name,
        aliases: this.props.aliases,
        text: this.props.text,
        embed: this.props.embed,
        adminOnly: this.props.adminOnly
    }

    render() {
        let edit = <></>
        if (this.props.writeAccess) {
            edit = <button className="edit-button" title="Edit This Macro" onClick={() => this.setState({editing: true})}><i className="fas fa-edit"></i></button>
        }
        let adminOnly = <></>
        if (this.state.adminOnly) {
            adminOnly = <i className="fas fa-shield-alt admin-only-icon" title="Admin Only"></i>
        }

        if (!this.state.editing) {
            return (
                <div className={"macro-widget" + (this.state.collapsed ? " collapsed" : "")}>
                    <div className="macro-title-container">
                        <h3 className="macro-title" onClick={() => this.handleCollapse()}>
                            <i className="fas fa-angle-down collapse-indicator"></i>
                            {this.state.name}
                            {adminOnly}
                        </h3>
                        {edit}
                    </div>
                    <div className="content">
                        <div className="macro-properties">
                            <div className="macro-property">
                                <b>Aliases:</b>
                                <p>{this.state.aliases.join(", ") || <i>none</i>}</p>
                            </div>
                        </div>
                        <div className="macro-preview discord">
                            <Discord text={this.state.text} embed={this.state.embed} />
                        </div>  
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="macro-widget editing">
                    <div className="macro-title-container">
                        <h3 className="macro-title">{this.state.name}</h3>
                    </div>
                    <div className="content">
                        <div className="macro-input-group">
                            <div className="macro-input">
                                <p className="macro-input-label">Macro Name:</p>
                                <input type="text" placeholder="Name..." value={this.state.name} onChange={(e) => this.setState({ name: e.target.value.toLowerCase().replace(/\s/g, "") })} />
                            </div>
                            <div className="macro-input">
                                <p className="macro-input-label"><abbr title="comma-separated">Macro Aliases</abbr>:</p>
                                <input type="text" placeholder="Alias 1, Alias 2, ..." value={this.state.aliases.join(", ")} onChange={(e) => this.setState({ aliases: e.target.value.toLowerCase().replace(/\s/g, "").split(",") })} />
                            </div>
                        </div>
                        <div className="macro-input">
                            <p className="macro-input-label">Macro Text:</p>
                            <textarea placeholder="Text..." value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
                        </div>
                        <div className="macro-input-group">
                            <div className="macro-input">
                                <p className="macro-input-label">Embed Title:</p>
                                <input type="text" placeholder="Title..." value={this.state.embed?.title || ""} onChange={
                                    (e) => this.setState({
                                        embed: {
                                            title: e.target.value, 
                                            description: this.state.embed?.description || "", 
                                            color: this.state.embed?.color || "#242529"
                                        }
                                    })
                                } />
                            </div>
                            <div className="macro-input small">
                                <p className="macro-input-label">Color:</p>
                                <input type="color" value={this.state.embed?.color?.replace("0x", "#") || "#242529"} onChange={
                                    (e) => this.setState({
                                        embed: {
                                            title: this.state.embed?.title || "", 
                                            description: this.state.embed?.description || "", 
                                            color: e.target.value
                                        }
                                    })
                                } />
                            </div>
                        </div>
                        <div className="macro-input">
                            <p className="macro-input-label">Embed Description:</p>
                            <textarea placeholder="Description..." value={ this.state.embed?.description || "" } onChange={
                                (e) => this.setState({
                                    embed: {
                                        title: this.state.embed?.title || "", 
                                        description: e.target.value, 
                                        color: this.state.embed?.color || "#242529"
                                    }
                                })
                            } />
                        </div>
                        <div className="macro-input-group macro-input-bottom">
                            <div className="macro-checkbox">
                                <input type="checkbox" value={this.state.adminOnly ? 1 : 0} checked={this.state.adminOnly} onChange={(e) => this.setState({ adminOnly: e.target.checked })} />
                                <p>Admin Only</p>
                            </div>
                            <div className="macro-edit-buttons">
                                <button className="rounded-button" onClick={() => this.props.handleDelete(this.props.id)}>Delete</button>
                                <button className="rounded-button" onClick={() => this.handleCancel()}>Cancel</button>
                                <button className="rounded-button" onClick={() => this.handleSave()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    handleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    handleCancel() {
        this.setState({
            collapsed: false,
            editing: false,
            name: this.props.name,
            aliases: this.props.aliases,
            text: this.props.text,
            embed: this.props.embed,
            adminOnly: this.props.adminOnly
        })
    }

    handleSave() {
        let macro = {
            name: this.state.name,
            aliases: this.state.aliases,
            message: {
                text: this.state.text,
                embed: this.state.embed
            },
            adminOnly: this.state.adminOnly
        }

        this.props.handleSave(this.props.id, macro)

        this.setState({
            collapsed: false,
            editing: false
        })
    }
}

export { MacroWidget }