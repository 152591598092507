import React from "react"

interface DropdownProps {
    text: string
}
interface DropdownState {
    collapsed: boolean
}
class Dropdown extends React.Component<DropdownProps, DropdownState> {
    state : DropdownState = {
        collapsed: true
    }

    render() {
        return (
            <div className={"dropdown" + (this.state.collapsed ? " collapsed" : "")}>
                <button className="dropdown-button rounded-button" onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
                    <p className="dropdown-text">{this.props.text}</p>
                    <i className="fas fa-angle-down dropdown-indicator"></i>
                </button>
                <div>
                    <div className="dropdown-menu">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export { Dropdown } 