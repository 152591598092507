import { Helmet } from "react-helmet"
import React from "react";

import "css/style-commissions.css"

function Commissions() {
    return ( 
        <>
            <Helmet>
                <title>Pixelguy - Commissions</title>
                <meta property="og:title" content="Pixelguy - Commissions"/>
            </Helmet>
            <h1>Pixelguy's Mapping Commissions</h1>
            <p>
                Hi, I'm Pixelguy! I've been a Beat Saber mapper for over 2 years, and I've released a variety of maps
                with songs of different genres.<br />
                I have familiarised myself with mapping, lighting, coding, and 3D design.<br />
                If you like my maps and want to commission me, check out my prices below!
            </p>
            <div id="commissions-closed">
                <i className="fas fa-exclamation-circle"></i>
                <p>Commissions are currently closed!</p>
            </div>
            <hr />
            <h2>Mapping Prices</h2>
            <div>
                <ul>
                    <li>
                        <p><em>€15</em> per minute for the highest difficulty level. (Standard/One Saber)</p>
                    </li>
                    <li>
                        <p><em>€20</em> per minute for a 360° level.</p>
                    </li>
                    <li>
                        <p><em>€10</em> per minute for any lower difficulty level.</p>
                    </li>
                </ul>
            </div>
            <hr />
            <h2>Lighting Prices</h2>
            <div>
                <ul>
                    <li>
                        <p><em>€15</em> per minute with Chroma.
                        </p>
                    </li>
                    <li>
                        <p><em>€10</em> per minute without Chroma.</p>
                    </li>
                    <li>
                        <p><em>Free:</em> Very basic lightshow without Chroma or boost-colors.</p>
                    </li>
                </ul>
            </div>
            <hr />
            <h2>Extras</h2>
            <div>
                <ul>
                    <li>
                        <p>
                            <em>€20+</em> per <abbr title="The client won't be charged extra when the same effect is used multiple times.">unique</abbr> Noodle Extensions effect.
                            This price might be higher depending on the difficulty of the effect.
                        </p>
                    </li>
                    <li>
                        <p><em>Free:</em> Extending or shortening audio. The new length will determine the final price.</p>
                    </li>
                    <li>
                        <p><em>Free:</em> Color overrides, environment overrides, boost colors.</p>
                    </li>
                </ul>
            </div>
            <hr />
            <h2>Additional Info</h2>
            <div>
                <ul>
                    <li>
                        <p><em>Banger Discount™</em>: If I happen to like the song, I will enforce a 30% discount on the total price.</p>
                    </li>
                    <li>
                        <p>I can decline a song if I don't like it.</p>
                    </li>
                    <li>
                        <p>With every commission, I will donate 10% of my earnings to the creators of the programs I used.</p>
                    </li>
                    <li>
                        <p>Song length will be rounded down to the nearest half-minute.</p>
                    </li>
                </ul>
            </div>
        </>
    )
}

export { Commissions }