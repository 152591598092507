enum StorageReturnType {
    string,
    number,
    json
}

class StorageTool {
    static getLocal(key : string, returnType : StorageReturnType) {
        let stored = localStorage.getItem(key)
        if (stored === null) return undefined

        return this.parseData(stored, returnType)
    }

    static setLocal(key : string, data : any) {
        if (typeof data !== "string") {
            data = JSON.stringify(data)
        }

        localStorage.setItem(key, data)
    }

    static removeLocal(key : string) {
        if (localStorage.getItem(key) !== null) {
            localStorage.removeItem(key)
        }
    }

    static getSession(key : string, returnType : StorageReturnType) {
        let stored = sessionStorage.getItem(key)
        if (stored === null) return undefined

        return this.parseData(stored, returnType)
    }

    static setSession(key : string, data : any) {
        if (typeof data !== "string") {
            data = JSON.stringify(data)
        }

        sessionStorage.setItem(key, data)
    }

    static removeSession(key : string) {
        if (sessionStorage.getItem(key) !== null) {
            sessionStorage.removeItem(key)
        }
    }

    static parseData(data : string, returnType : StorageReturnType) {
        switch (returnType) {
            case StorageReturnType.string: {
                return data
            }
            case StorageReturnType.number: {
                let number = parseFloat(data)
                if (isNaN(number)) return null
                else return number
            }
            case StorageReturnType.json: {
                try {
                    let json = JSON.parse(data) 
                    return json
                }
                catch {
                    return null
                }
            }
        }
    }
}

export {
    StorageTool,
    StorageReturnType
}