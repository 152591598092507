import React from "react"
import { StorageTool, StorageReturnType, DiscordAPI, PixelAPI } from "modules"
import { AuthData, UserData } from "types"

interface LoginProps {
}
interface LoginState {
    userData? : UserData
}
class Login extends React.Component<LoginProps, LoginState> {
    constructor(props : LoginProps) {
        super(props)

        this.state = {}
    }

    async componentDidMount() {
        let authData : AuthData | null = StorageTool.getLocal("auth-data", StorageReturnType.json) as AuthData
        let userData : UserData | null = StorageTool.getSession("user-data", StorageReturnType.json) as UserData

        if (authData === undefined) return

        if (authData.token === undefined || authData.expiration === undefined) {
            StorageTool.removeLocal("auth-data")
            return
        }

        if (authData.expiration < Date.now()) {
            authData = await this.refreshToken(authData.refresh).then((newAuthData : AuthData | null) => {
                StorageTool.removeSession("user-data")
                userData = null

                if (newAuthData === null) {
                    StorageTool.removeLocal("auth-data")
                    return null
                }

                StorageTool.setLocal("auth-data", newAuthData)
                return newAuthData
            })
        }
        
        if (authData) {
            if (userData) {
                this.setState({
                    userData: userData
                })
            }

            this.getUserData(authData.token).then(data =>  {
                if (data === null) return
                this.setState({
                    userData: data
                })
                
                StorageTool.setSession("user-data", data)
            })
        }
        else {
            StorageTool.removeSession("user-data")
        }
    }

    render() {
        if (this.state.userData === undefined) return (
            <div className="settings-element rounded-button" id="account" onClick={ () => this.loginRedirect() }>
                <i className="fab fa-discord"></i>
                <p>Log In</p>
            </div>
        )
        return (
            <div className="settings-element rounded-button" id="account" onClick={ () => this.logout() }>
                <img src={this.state.userData.avatarURL} alt="" id="avatar"></img>
                <p>{this.state.userData.username}</p>
            </div>
        )
    }

    async getUserData(token : string) {
        let res = await DiscordAPI.getUserData(token)
        if (!res.succeeded) {
            StorageTool.removeSession("auth-data")
            return null
        }

        let userData : UserData = {
            id : res.data.id,
            username : res.data.username,
            avatarURL : `https://cdn.discordapp.com/avatars/${res.data.id}/${res.data.avatar}.webp?size=512`
        }

        return userData
    }

    async refreshToken(refreshToken : string) {
        let res = await DiscordAPI.refreshToken(refreshToken)
        if (!res.succeeded) return null
        
        let expiration = Date.now() + (parseInt(res.data.expires_in) || 0)
        await PixelAPI.postToken(res.data.access_token, expiration) 
        
        let authData : AuthData = {
            token: res.data.access_token, 
            expiration: expiration,
            refresh: res.data.refresh_token
        }
        return authData
    }

    loginRedirect() {
        // const loginURL = "https://discord.com/api/oauth2/authorize?client_id=862975725237436416&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth&response_type=code&scope=guilds%20identify"
        // const loginURL = "https://discord.com/api/oauth2/authorize?client_id=862975725237436416&redirect_uri=http%3A%2F%2Fdev.pixelguy.me%2Fauth&response_type=code&scope=identify%20guilds"
        const loginURL = "https://discord.com/api/oauth2/authorize?client_id=862975725237436416&redirect_uri=https%3A%2F%2Fwww.pixelguy.me%2Fauth&response_type=code&scope=identify%20guilds"
        window.location.href = loginURL
    }

    logout() {
        StorageTool.removeLocal("auth-data")
        StorageTool.removeSession("user-data")
        
        this.setState({
            userData: undefined
        })
    }
}

export { Login }