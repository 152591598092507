import React from "react"
import ReactDOM from "react-dom"
import { Body } from "./Body"

import "./css/style-base.css"
import "./css/style-home.css"

ReactDOM.render(
  <React.StrictMode>
    <Body />
  </React.StrictMode>,
  document.getElementById("root")
);