import { Markdown } from "modules"
import React from "react"

interface DiscordProps {
    text: string,
    embed?: {
        title?: string | null,
        description?: string | null,
        color?: string | null
    }
}
class Discord extends React.Component<DiscordProps> {
    render() {
        return (
            <>
                <div dangerouslySetInnerHTML={{__html: Markdown.parse(this.props.text)}}></div>
                {this.getEmbed()}
            </>
        )
    }

    getEmbed() {
        if ((this.props.embed?.title || "") === "" && (this.props.embed?.description || "") === "") return <></>

        let color = "#242529"
        if (this.props.embed?.color) {
            color = this.props.embed.color.replace("0x", "#")
        }

        let style : React.CSSProperties = {
            borderColor: color
        } 
        return (
            <div className="embed" style={style}>
                <span className="embed-title">{this.props.embed?.title}</span>
                <p className="embed-body" dangerouslySetInnerHTML={{__html: Markdown.parse(this.props.embed?.description || "")}}></p>
            </div>
        )
    }
}

export { Discord }