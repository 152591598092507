import React from "react"

import "css/style-loading.css"

class Loading extends React.Component {
    render() {
        return (
            <div className="centered">
                <div id="loading">
                    <div className="loading-dot" id="dot-1"></div>
                    <div className="loading-dot" id="dot-2"></div>
                    <div className="loading-dot" id="dot-3"></div>
                </div>
                <p>Loading...</p>
            </div>
        ) 
    }
}

export { Loading }