import React from "react"
import { StorageTool, WebTool, DiscordAPI, PixelAPI } from "modules"
import { Loading } from "pages/components"
import { AuthData } from "types"

class Auth extends React.Component {
    render() {
        return (
            <><Loading /></>
        )
    }

    componentDidMount() {
        let code = WebTool.getURLparam("code")
        this.getUserData(code).then(() => {
            window.location.href = "/"
        })
    }

    async getUserData(code : string) {
        if (code === undefined) return

        let authData : AuthData | null = await this.getToken(code)
        if (authData === null) return

        StorageTool.setLocal("auth-data", authData)
        StorageTool.removeSession("user-data")
    }

    async getToken(code : string) { 
        let token = await DiscordAPI.getAuthToken(code)
        if (!token.succeeded) return null
        let expiration = Date.now() + (parseInt(token.data.expires_in) || 0)
        let res = await PixelAPI.postToken(token.data.access_token, expiration)
        if (!res.succeeded) return null

        let authData : AuthData = {
            token: token.data.access_token, 
            expiration: expiration,
            refresh: token.data.refresh_token
        }
        return authData
    }
}

export { Auth }