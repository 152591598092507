import { Helmet } from "react-helmet"
import { Carousel, CarouselElement } from "pages/components"

function Home() {
    return (
        <>
            <Helmet>
                <title>Pixelguy - Home</title>
                <meta property="og:title" content="Pixelguy - Home"/>
            </Helmet>
            <div className="centered">
                <h1 id="main-title">Pixelguy</h1>
                <Carousel>
                    <CarouselElement title="3D Art" imageURL="/assets/images/3DArtBanner.png"/>
                    <CarouselElement title="Maps" imageURL="/assets/images/MapsBanner.png"/>
                    <CarouselElement title="Lightshows" imageURL="/assets/images/LightshowBanner.png"/>
                </Carousel>
            </div>
        </>
    )
}

export { Home }