import React from "react"
import { Progress } from "pages/components"

interface SongListItemProps {
    key: string,
    id: string,
    handleDelete: (id : string) => void,
    handleSave: (id : string, pack : any) => void,
    name: string,
    link: string,
    mapper: string,
    contributors: string,
    progress: string
}
interface SongListItemState {
    collapsed: boolean,
    editing: boolean,
    name: string,
    link?: string,
    mapper: string,
    contributors: string,
    progress: number
}

class SongListItem extends React.Component<SongListItemProps, SongListItemState> {
    state : SongListItemState = {
        collapsed: true,
        editing: false,
        name: this.props.name,
        link: this.props.link,
        mapper: this.props.mapper,
        contributors: this.props.contributors,
        progress: parseFloat(this.props.progress) || 0
    }

    render() {
        if (!this.state.editing) {
            return (
                <div className={"song-list-item" + (this.state.collapsed ? " song-collapsed" : "")}>
                    <div className="song-title-container">
                        <b className="song-title" onClick={ () => this.handleCollapse() }>
                            <i className="fas fa-angle-down song-collapse-indicator"></i>
                            {this.state.mapper} - {this.state.name}
                        </b>
                        <Progress progress={this.state.progress} />
                        <button className="song-edit-button" title="Edit This Song" onClick={() => this.setState({editing: true})}><i className="fas fa-pencil"></i></button>
                    </div>
                    <div className="song-properties">
                        <div className="song-property">
                            <b>Song Name:</b>
                            {this.state.link ? <a href={this.state.link} target="_blank" rel="noreferrer">{this.state.name}</a> : <p>{this.state.name}</p>}
                        </div>
                        <div className="song-property">
                            <b>Mapper:</b>
                            <p>{this.state.mapper}</p>
                        </div>
                        <div className="song-property">
                            <b>Contributors:</b>
                            <p>{this.state.contributors}</p>
                        </div>
                        <div className="song-property">
                            <b>Progress:</b>
                            <p>{this.state.progress} %</p>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="song-list-item editing">
                    <div className="song-title-container">
                        <b className="song-title">
                            {this.state.mapper} - {this.state.name}
                        </b>
                        <Progress progress={this.state.progress} />
                    </div>
                    <div className="song-properties">
                        <div className="song-property">
                            <b>Song Name:</b>
                            <div className="song-input-container">
                                <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            </div>
                        </div>
                        <div className="song-property">
                            <b>Song Link:</b> 
                            <div className="song-input-container">
                                <input type="text" value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} />
                            </div>
                        </div>
                        <div className="song-property">
                            <b>Mapper:</b>
                            <div className="song-input-container">
                                <input type="text" value={this.state.mapper} onChange={(e) => this.setState({ mapper: e.target.value })} />
                            </div>
                        </div>
                        <div className="song-property">
                            <b>Contributors:</b>
                            <div className="song-input-container">
                                <input type="text" value={this.state.contributors} onChange={(e) => this.setState({ contributors: e.target.value })} />
                            </div>
                        </div>
                        <div className="song-property">
                            <b>Progress:</b>
                            <div className="song-input-container">
                                <input type="text" value={this.state.progress} onChange={(e) => this.setState({ progress: parseFloat(e.target.value) || 0.0})} />
                                <p>%</p>
                            </div>
                        </div>
                    </div>
                    <div className="song-input-bottom">
                        <div className="song-edit-buttons">
                            <button className="rounded-button" onClick={() => this.props.handleDelete(this.props.id)}>Delete</button>
                            <button className="rounded-button" onClick={() => this.handleCancel()}>Cancel</button>
                            <button className="rounded-button" onClick={() => this.handleSave()}>Save</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    handleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    handleCancel() {
        this.setState({
            collapsed: false,
            editing: false,
            name: this.props.name,
            mapper: this.props.mapper,
            contributors: this.props.contributors,
            link: this.props.link,
            progress: parseFloat(this.props.progress) || 0
        })
    }

    async handleSave() {
        let song = {
            name: this.state.name,
            mapper: this.state.mapper,
            contributors: this.state.contributors,
            link: this.state.link,
            progress: this.state.progress
        }

        await this.props.handleSave(this.props.id, song)

        this.setState({
            collapsed: false,
            editing: false
        })
    }
}

export { SongListItem }