import { AxiosResponse } from "axios"

interface APIResponse {
    succeeded: boolean,
    data?: any,
    errorCode?: number
}

class APITool {
    static async request(req : () => Promise<AxiosResponse<any>>) {
        let res = await req().then(res => {
            return {
                succeeded: res.status === 200,
                data: res.data
            } as APIResponse
        })
        .catch((e) => {
            return {
                succeeded: false,
                errorCode: e.response?.status
            } as APIResponse
        })
    
        return res
    }
}

export { APITool }