class Markdown {
    static parse(text: string) {
        let md = text
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")

        // parsing code blocks manually since it works slightly different.
        let open = true
        let codeblockCheck = /(?<!\\)`{3}/
        while (codeblockCheck.test(md)) {
            if (open) md = md.replace(/(?<!\\)`{3}.*\n?/, `<div class="codeblock">`)
            else md = md.replace(/\n?(?<!\\)`{3}/, `</div>`)
            open = !open
        }
        if (!open) {
            md += (`</div>`)
        }
        // canceling markdown in code blocks
        md = this.cancelMarkdown(md, `<div class="codeblock">`, `</div>`)
        
        // inline code
        md = md.replace(/(?<!\\)`(.+?)`/g, `<code>$1</code>`)
        // canceling markdown in code
        md = this.cancelMarkdown(md, `<code>`, `</code>`)
        
        // bold
        md = md.replace(/(?<!\\)\*{2}(.+?)\*{2}/g, `<b>$1</b>`)
        // underline 
        md = md.replace(/(?<!\\)_{2}(.+?)_{2}/g, `<u>$1</u>`)
        // italics (both ways)
        md = md.replace(/(?<![\\*])\*(.+?)\*/g, `<i>$1</i>`).replace(/(?<![\\_])_(.+?)_/g, `<i>$1</i>`)
        // strike through
        md = md.replace(/(?<!\\)~{2}(.+?)~{2}/g, `<strike>$1</strike>`)
        // quotes
        md = md.replace(/^&gt;\s(.+)/gm, `<blockquote>$1</blockquote>`)
        // links
        md = md.replace(/(?<!\\)&lt;(https?:\/\/\S+?\.\S+?)&gt;/g, "$1")
        md = md.replace(/(?<!\\)(?<!\[.*?]\()(https?:\/\/\S+?\.\S+?)(?=\s|$|\))/g, "<a href=\"$1\">$1</a>")
        // hyperlinks
        md = md.replace(/(?<!\\)\[(.+?)]\((https?:\/\/\S+?\.\S+?)\)/g, "<a href=\"$2\">$1</a>")
        // mentions and channels
        md = md.replace(/&lt;@(.+?)(&gt;)/g, `<mark>@$1</mark>`)
        md = md.replace(/&lt;#(.+?)(&gt;)/g, `<mark>#$1</mark>`)
        // spoilers
        md = md.replace(/(?<!\\)\|{2}(.+?)\|{2}/g, `<span class="spoiler censored">$1</span>`)

        // replacing newlines with html breaklines
        md = md.replace(/\n/g, "<br>")
        // remove \ canceling markdown
        md = md.replace(/\\(?=[*_~`[]|(&gt;)|(http))/g, "")

        return md
    }

    private static cancelMarkdown(text = "", start = "", end = "") {
        if (!text.includes(start)) return text

        let canceled = ""

        while (text.includes(start)) {
            // breaking the text up into parts.
            canceled += text.slice(0, text.indexOf(start) + start.length)
            let cancel = text.slice(text.indexOf(start) + start.length, text.indexOf(end))

            // canceling markdown characters
            cancel = cancel.replace(/\*/g, "\\*")
            cancel = cancel.replace(/_/g, "\\_")
            cancel = cancel.replace(/~/g, "\\~")
            cancel = cancel.replace(/`/g, "\\`")
            cancel = cancel.replace(/(&gt;)/g, "\\&gt;")
            cancel = cancel.replace(/(https?:\/\/\S+?\.\S+?)/g, "\\$1")
            cancel = cancel.replace(/\[/g, "\\[")

            canceled += cancel

            canceled += text.slice(text.indexOf(end), text.indexOf(end) + end.length)

            text = text.slice(text.indexOf(end) + end.length)
        }
        canceled += text

        return canceled
    }
}

export { Markdown }