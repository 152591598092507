import React from "react"

interface GuildWidgetProps {
    key: string,
    id : string,
    name : string,
    image : string,
    macroCount : number,
    packCount : number
}
class GuildWidget extends React.Component<GuildWidgetProps> {
    render() {
        return  (
            <div className="guild-widget">
                <div className="guild-title">
                    <img src={this.props.image} alt="" className="guild-image" />
                    <h2>{this.props.name}</h2>
                </div>
                <div className="guild-info">
                    <p><em>{this.props.macroCount}</em> Macro{this.props.macroCount === 1 ? "" : "s"}</p>
                    <p><em>{this.props.packCount}</em> Pack{this.props.packCount === 1 ? "" : "s"}</p>
                </div>
                <div className="guild-edit-buttons">
                    <a href={`/mapperbot/macros?id=${this.props.id}`} className="rounded-button">View Macros</a>
                    <a href={`/mapperbot/packs?id=${this.props.id}`} className="rounded-button">View Packs</a>
                </div>
            </div>
        )
    }
}

export { GuildWidget }