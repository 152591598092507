import Axios from "axios"
import { StorageTool, StorageReturnType, APITool } from "modules"
import { AuthData } from "types"

class PixelAPI {
    private static url : string = "https://api.pixelguy.me"
    private static headers() {
        let authData : AuthData = StorageTool.getLocal("auth-data", StorageReturnType.json) as AuthData
        if (authData === undefined) return undefined

        return { Authorization: authData.token }
    }

    static async postToken(token : string, expiration : number) {
        let res = await APITool.request(
            () => Axios.post(
                this.url + `/mapperbot/tokens/`, 
                { token: token, expiration: expiration }
            )
        )

        return res
    }

    static async getAccess() {
        let res = await APITool.request(
            () => Axios.get(
                this.url + `/mapperbot/tokens/`, 
                { headers: this.headers() }
            )
        )

        return res
    }

    static async getGuilds() {
        let res = await APITool.request(
            () => Axios.get(
                this.url + `/mapperbot/guilds/`, 
                { headers: this.headers() }
            )
        )

        return res
    }

    //#region Macros
    static async getMacros(guildId : string, order : string) {
        let res = await APITool.request(
            () => Axios.get(
                this.url + `/mapperbot/macros/by-guild/${guildId}?order=${order}`, 
                { headers: this.headers() }
            )
        )

        return res
    }

    static async postMacro(macro : any) {
        let res = await APITool.request(
            () => Axios.post(
                this.url + `/mapperbot/macros/`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async putMacro(id : string, macro : any) {
        let res = await APITool.request(
            () => Axios.put(
                this.url + `/mapperbot/macros/${id}`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async deleteMacro(id : string) {
        let res = await APITool.request(
            () => Axios.delete(
                this.url + `/mapperbot/macros/${id}`,
                { headers: this.headers() }
            )
        )

        return res
    }
    //#endregion

    //#region Packs
    static async getPacks(guildId : string, order : string) {
        let res = await APITool.request(
            () => Axios.get(
                this.url + `/mapperbot/packs/by-guild/${guildId}?order=${order}`, 
                { headers: this.headers() }
            )
        )

        return res
    }

    static async postPack(macro : any) {
        let res = await APITool.request(
            () => Axios.post(
                this.url + `/mapperbot/packs/`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async putPack(id : string, macro : any) {
        let res = await APITool.request(
            () => Axios.put(
                this.url + `/mapperbot/packs/${id}`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async deletePack(id : string) {
        let res = await APITool.request(
            () => Axios.delete(
                this.url + `/mapperbot/packs/${id}`,
                { headers: this.headers() }
            )
        )

        return res
    }
    //#endregion

    //#region Songs
    static async getSongs(packId : string, order : string) {
        let res = await APITool.request(
            () => Axios.get(
                this.url + `/mapperbot/songs/by-pack/${packId}?order=${order}`, 
                { headers: this.headers() }
            )
        )

        return res
    }

    static async postSong(macro : any) {
        let res = await APITool.request(
            () => Axios.post(
                this.url + `/mapperbot/songs/`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async putSong(id : string, macro : any) {
        let res = await APITool.request(
            () => Axios.put(
                this.url + `/mapperbot/songs/${id}`,
                macro,
                { headers: this.headers() }
            )
        )

        return res
    }

    static async deleteSong(id : string) {
        let res = await APITool.request(
            () => Axios.delete(
                this.url + `/mapperbot/songs/${id}`,
                { headers: this.headers() }
            )
        )

        return res
    }
    //#endregion
}

export { PixelAPI }