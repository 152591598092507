import React from "react"

interface CarouselProps {
}
interface CarouselState {
    cycle : number
}
class Carousel extends React.Component<CarouselProps, CarouselState> {
    state : CarouselState = {
        cycle: 0
    }

    constructor(props : CarouselProps) {
        super(props)

        this.state = {
            cycle: 0
        }
    }

    componentDidMount() {
        setInterval(() => this.moveLeft(), 5000)
    }

    render() {
        return (
            <div className="carousel">
                {
                    React.Children.map(this.props.children, (child, i) => {
                        if (!React.isValidElement<CarouselElementProps>(child)) {
                           return child 
                        }

                        const title : string = child.props.title
                        const imageURL : string = child.props.imageURL
                        const position : number = this.getPosition(i, this.state.cycle)
                        return <CarouselElement title={ title } imageURL={ imageURL } position={ position }/>
                    })
                }
            </div>
        )
    }

    moveLeft() {
        const childCount = React.Children.count(this.props.children)

        let nextCycle : number
        if (this.state.cycle < childCount - 1) {
            nextCycle = this.state.cycle + 1
        } 
        else {
            nextCycle = 0
        }

        this.setState({
            cycle: nextCycle
        })
    }

    getPosition(index : number, cycle : number) {
        const childCount = React.Children.count(this.props.children)
        const offset : number = index - 1 - cycle

        if (offset < -1) {
            return childCount + offset
        }
        else {
            return offset
        }
    }
}

interface CarouselElementProps {
    title : string,
    imageURL : string,
    position? : number
}
class CarouselElement extends React.Component<CarouselElementProps> {
    render() {
        return (
            <div className="carousel-element" style={ this.getStyle(this.props.position) || {}}>
                <img className="carousel-image" src={ this.props.imageURL } alt=""/>
                <div className="carousel-label">
                    <h2>{ this.props.title }</h2>
                </div>
            </div>
        )
    }

    getStyle(position? : number) {
        if (position === undefined) return

        let style : React.CSSProperties = {
            left: `${position * 100}%`,
            zIndex: position * -1
        }

        return style
    }
}

export { Carousel, CarouselElement }