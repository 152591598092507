import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { WebTool } from "modules"
import { Toolbar, Footer } from "pages/components"
import { Home, Commissions, Auth, MapperBot, Macros, Packs } from "pages"
import { Alert } from "context"

interface BodyProps {
}
interface BodyState {
    showAlert : boolean,
    alertTitle : string,
    alertBody : string
}
class Body extends React.Component<BodyProps, BodyState> {
    state : BodyState = {
        showAlert: false,
        alertTitle: "",
        alertBody: ""
    }

    render() {
        let alert = <></>
        if (this.state.showAlert) {
            alert = <div id="alert">
                <b id="alert-title">{this.state.alertTitle}</b>
                <p id="alert-body">{this.state.alertBody}</p>
                <button className="rounded-button" onClick={() => this.closeAlert()}>OK</button>
            </div>
        }

        return (
            <>
                <Helmet>
                    <meta property="og:site_name" content="pixelguy.me"/>
                    <meta property="og:image" content="/assets/images/logo512.png"/>
                    <meta property="theme-color" content="#9db9d4"/>
                </Helmet>
                <main className={this.state.showAlert ? "darkened" : ""}>
                    <Alert.Provider value={{ show: (title, body) => this.showAlert(title, body) }}>
                        <Router>
                            <Toolbar />
                            <div id="main-window">
                                <Switch>
                                    <Route path="/" exact component={() => <Home />} />
                                    <Route path="/commissions" exact component={() => <Commissions />} />
                                    <Route path="/auth" exact component={() => <Auth />} />
                                    <Route path="/mapperbot" exact component={() => <MapperBot />} />
                                    <Route path="/mapperbot/macros" component={() => <Macros guildId={WebTool.getURLparam("id")} />} />
                                    <Route path="/mapperbot/packs" component={() => <Packs guildId={WebTool.getURLparam("id")} />} />
                                </Switch>
                            </div>
                            <Footer />
                        </Router>
                    </Alert.Provider>
                </main>
                <div id="alert-container" className={this.state.showAlert ? "active" : ""}>
                    {alert}
                </div>
            </>
        )
    }

    showAlert(title : string, body : string) {
        this.setState({
            showAlert: true,
            alertTitle: title,
            alertBody: body
        })
    }

    closeAlert() {
        this.setState({
            showAlert: false,
            alertTitle: "",
            alertBody: ""
        })
    }
}

export { Body }