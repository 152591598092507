import React from "react"

interface ProgressProps {
    progress: number
}
class Progress extends React.Component<ProgressProps> {
    render() {
        return (
            <div className="progress-bar" style={this.getStyle()} title={this.props.progress + " %"}></div>
        )
    }

    getStyle() {
        let style : React.CSSProperties = {
            backgroundImage: `linear-gradient(90deg, var(--emphasis) ${this.props.progress}%, var(--background-primary) 0%)`
        }

        return style
    }
}

export { Progress } 