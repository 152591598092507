import React from "react"
import { Loading, SongListItem, Dropdown, Progress } from "pages/components"
import { PixelAPI, StorageTool, StorageReturnType, Markdown } from "modules"
import { UserData } from "types"
import { Alert } from "context"

interface PackWidgetProps {
    key: string,
    id: string,
    deleteAccess: boolean,
    handleDelete: (id : string) => void,
    handleSave: (id : string, pack : any) => void,
    name: string,
    requirements: string,
    deadline: string,
    creatorId: string
    guildId: string
}
interface PackWidgetState {
    collapsed: boolean,
    editing: boolean,
    name: string,
    requirements: string,
    deadline: string,
    creatorId: string,
    songOrder: string,
    songs?: Array<any> | null
}

class PackWidget extends React.Component<PackWidgetProps, PackWidgetState> {
    static contextType = Alert
    state : PackWidgetState = {
        collapsed: true,
        editing: false,
        name: this.props.name,
        requirements: this.props.requirements,
        deadline: this.props.deadline,
        creatorId: this.props.creatorId,
        songOrder: "Mapper"
    }

    async componentDidMount() {
        let songs = await PixelAPI.getSongs(this.props.id, this.state.songOrder.toLowerCase()).then(res => res.data) as Array<any>

        this.setState({
            songs: songs
        })
    }

    render() {
        let del = <></>
        if (this.props.deleteAccess) {
            del = <button className="rounded-button" onClick={() => this.props.handleDelete(this.props.id)}>Delete</button>
        }

        if (!this.state.editing) {
            return (
                <div className={"pack-widget" + (this.state.collapsed ? " collapsed" : "")}>
                    <div className="pack-title-container">
                        <h3 className="pack-title" onClick={() => this.handleCollapse()}>
                            <i className="fas fa-angle-down collapse-indicator"></i>
                            {this.state.name}
                        </h3>
                        <Progress progress={this.getTotalProgress()} />
                        <button className="edit-button" title="Edit This Pack" onClick={() => this.setState({ editing: true })}><i className="fas fa-edit"></i></button>
                    </div>
                    <div className="content">
                        <div className="pack-properties">
                            <div className="pack-property">
                                <b>Pack Name:</b>
                                <p>{this.state.name || <i>none</i>}</p>
                            </div>
                            <div className="pack-property">
                                <b>Requirements:</b>
                                <p dangerouslySetInnerHTML={{__html: Markdown.parse(this.state.requirements || "*none*")}}></p>
                            </div>
                            <div className="pack-property">
                                <b>Deadline:</b>
                                <p>{this.state.deadline || <i>none</i>}</p>
                            </div>
                            <div className="pack-property">
                                <b>Total Progress:</b>
                                <p>{this.getTotalProgress()} %</p>
                            </div>
                        </div>
                        <div className="song-sort-options">
                            <p>Order by:</p>
                            <Dropdown text={this.state.songOrder}>
                                <button className="dropdown-item" onClick={() => this.changeSongOrder("Mapper")}>Mapper</button>
                                <button className="dropdown-item" onClick={() => this.changeSongOrder("Name")}>Name</button>
                                <button className="dropdown-item" onClick={() => this.changeSongOrder("Date")}>Date</button>
                            </Dropdown>
                        </div>
                        <div className="song-list">
                            { this.getSongListItems() }
                            <button className="song-add-button" title="Add a Song" onClick={() => this.handleSongAdd()}><i className="fas fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="pack-widget editing">
                    <div className="pack-title-container">
                        <h3 className="pack-title">{this.state.name}</h3>
                    </div>
                    <div className="content">
                        <div className="pack-input">
                            <p className="pack-input-label">Pack Name:</p>
                            <input type="text" placeholder="Name..." value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                        <div className="pack-input">
                            <p className="pack-input-label">Requirements:</p>
                            <textarea placeholder="Requirements..." value={this.state.requirements} onChange={(e) => this.setState({ requirements: e.target.value })} />
                        </div>
                        <div className="pack-input">
                            <p className="pack-input-label">Deadline:</p>
                            <input type="text" placeholder="Deadline..." value={this.state.deadline} onChange={(e) => this.setState({ deadline: e.target.value })} />
                        </div>
                        <div className="pack-input-group pack-input-bottom">
                            <div className="pack-edit-buttons">
                                {del}
                                <button className="rounded-button" onClick={() => this.handleCancel()}>Cancel</button>
                                <button className="rounded-button" onClick={() => this.handleSave()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getTotalProgress() {
        if (!this.state.songs || this.state.songs.length === 0) return 0.0
        else return Math.round(this.state.songs?.map(s => 
            parseFloat(s.progress) || 0
        ).reduce((sum, n) => 
            sum + n
        ) / (this.state.songs?.length || 1) * 10) / 10
    }

    getSongListItems() {
        if (this.state?.songs === undefined) {
            return (
                <div className="centered">
                    <Loading />
                </div>
            )
        }
        if (this.state?.songs == null) {
            return (
                <div className="centered">
                    <p>Can't find any songs.</p>
                </div>
            )
        }

        let widgets : React.ReactElement[] = []
        for (let song of this.state.songs) {
            widgets.push(
                <SongListItem 
                    key = { song._id }
                    id = { song._id }
                    handleDelete = { (id : string) => this.handleSongDelete(id) }
                    handleSave = { ( id : string, song : any ) => this.handleSongSave(id, song) }
                    name = { song.name }
                    link = { song.link }
                    mapper = { song.mapper }
                    contributors = { song.contributors }
                    progress = { song.progress }
                />
            )
        }

        return widgets
    }

    handleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    handleCancel() {
        this.setState({
            collapsed: false,
            editing: false,
            name: this.props.name,
            deadline: this.props.deadline,
            requirements: this.props.requirements,
            creatorId: this.props.creatorId
        })
    }

    handleSave() {
        let pack = {
            name: this.state.name,
            deadline: this.state.deadline,
            requirements: this.state.requirements,
            creatorId: this.state.creatorId
        }

        this.props.handleSave(this.props.id, pack)

        this.setState({
            collapsed: false,
            editing: false
        })
    }

    async handleSongDelete(id : string) {
        let res = await PixelAPI.deleteSong(id)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to delete songs.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to delete this song.")
            return
        }

        let songs = await PixelAPI.getSongs(this.props.id, this.state.songOrder.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            songs: songs
        })
    }

    async handleSongAdd() { 
        let userData : UserData = StorageTool.getSession("user-data", StorageReturnType.json)
        let data = { name: "New Song", guildId: this.props.guildId, packId: this.props.id, mapper: userData?.username || "", progress: 0 }

        let res = await PixelAPI.postSong(data)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to add songs.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to add a new song.")
            return
        }

        let songs = await PixelAPI.getSongs(this.props.id, this.state.songOrder.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            songs: songs
        })
    }

    async handleSongSave(id : string, song : any) {
        let res = await PixelAPI.putSong(id, song)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to edit songs.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to edit this song.")
            return
        }

        let songs = await PixelAPI.getSongs(this.props.id, this.state.songOrder.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            songs: songs
        })
    }

    async changeSongOrder(order: string) {
        let songs = await PixelAPI.getSongs(this.props.id, order).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            songOrder: order,
            songs: songs
        })
    }
}

export { PackWidget }