import React from "react"
import { Helmet } from "react-helmet"
import { Loading, MacroWidget, Dropdown } from "pages/components"
import { GuildInfo } from "types"
import { Alert } from "context"

import "css/style-macros.css"
import "css/style-discord.css"
import { PixelAPI } from "modules"

interface MacrosProps {
    guildId : string
}
interface MacrosState {
    order: string,
    apiAccess?: any | null
    macros?: Array<any> | null,
    guild?: GuildInfo | null
}
class Macros extends React.Component<MacrosProps, MacrosState> {
    static contextType = Alert
    state : MacrosState = { order: "Name" }

    async componentDidMount() {
        let guilds = await PixelAPI.getGuilds().then(res => res.succeeded ? res.data : []) as Array<GuildInfo>
        let guild = guilds.find(g => g.id === this.props.guildId)
        let access = await PixelAPI.getAccess().then(res => res.data)
        let macros = await PixelAPI.getMacros(this.props.guildId, this.state.order.toLowerCase()).then(res => res.data) as Array<any>
        console.log(macros)

        this.setState({
            apiAccess: access || null,
            macros: macros,
            guild: guild || null
        })
    }

    render() {
        if (this.state.guild === undefined || this.state.apiAccess === undefined) return <Loading />
        if (this.state.guild == null || this.state.apiAccess == null) return (
            <>
                <Helmet>
                    <title>Pixelguy - Macros</title>
                    <meta property="og:title" content="Pixelguy - Macros"/>
                </Helmet>
                <div className="centered">
                    <h2>The requested guild can not be found.</h2>
                    <p>Please go back to the <a href="/mapperbot">previous page</a> and select a guild from there.</p>
                </div>
            </>
        )

        let permissions : any = this.state.apiAccess.guilds.find((g: any) => g.id === this.props.guildId)
        let writeAccess = this.state.apiAccess.administrator || permissions.write

        let add = <></>
        if (writeAccess) {
            add = <button className="add-button" title="Add a Macro" onClick={() => this.handleAdd()}><i className="fas fa-plus"></i></button>
        }

        return ( 
            <>
                <Helmet>
                    <title>Pixelguy - Macros</title>
                    <meta property="og:title" content="Pixelguy - Macros"/>
                </Helmet>
                <div className="centered">
                    <div id="center">
                        <div id="guild-title">
                            <img src={`https://cdn.discordapp.com/icons/${this.props.guildId}/${this.state.guild.icon}.webp?size=128`} alt="" className="guild-image" />
                            <h1>{this.state.guild.name}</h1>
                        </div>
                        <div className="filter-sort-options">
                            <p>Order by:</p>
                            <Dropdown text={this.state.order}>
                                <button className="dropdown-item" onClick={() => this.changeOrder("Name")}>Name</button>
                                <button className="dropdown-item" onClick={() => this.changeOrder("Date")}>Date</button>
                            </Dropdown>
                        </div>
                        <div id="macros-container">
                            {this.getMacroWidgets(writeAccess)}
                        </div>
                        {add}
                    </div>
                </div>
            </>
        )
    }

    getMacroWidgets(writeAccess : boolean) {
        if (this.state?.macros === undefined) {
            return (<Loading />)
        }
        if (this.state?.macros == null) {
            return (
                <div className="centered">
                    <h2>Can't find any macros.</h2>
                </div>
            )
        }

        let widgets : React.ReactElement[] = []
        for (let macro of this.state.macros) {
            widgets.push(
                <MacroWidget 
                    key = { macro._id }
                    id = { macro._id }
                    writeAccess = { writeAccess }
                    handleDelete = { (id : string) => this.handleDelete(id) }
                    handleSave = { ( id : string, macro : any ) => this.handleSave(id, macro) }
                    name = { macro.name }
                    aliases = { macro.aliases }
                    text = { macro.message?.text || ""}
                    embed = { macro.message?.embed || {} }
                    adminOnly = { macro.adminOnly || false}
                />
            )
        }

        return widgets
    }

    async handleDelete(id : string) {
        let res = await PixelAPI.deleteMacro(id)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to delete macros.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to delete this macro.")
            return
        }

        let macros = await PixelAPI.getMacros(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            macros: macros
        })
    }

    async handleAdd() {
        let data = { name: "new", guildId: this.props.guildId }

        let res = await PixelAPI.postMacro(data)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to add new macros.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to add a new macro.")
            return
        }

        let macros = await PixelAPI.getMacros(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            macros: macros
        })
    }

    async handleSave(id : string, macro : any) {
        let res = await PixelAPI.putMacro(id, macro)
        if (!res.succeeded) {
            if (res.errorCode === 401 || res.errorCode === 403) {
                this.context.show("Error: " + res.errorCode, "You do not have the permission to edit macros.")
            }
            else this.context.show("Error: " + res.errorCode, "Something went wrong while trying to edit this macro.")
            return
        }

        let macros = await PixelAPI.getMacros(this.props.guildId, this.state.order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            macros: macros
        })
    }

    async changeOrder(order: string) {
        let macros = await PixelAPI.getMacros(this.props.guildId, order.toLowerCase()).then(res => res.succeeded ? res.data : []) as Array<any>
        this.setState({
            order: order,
            macros: macros
        })
    }
}

export { Macros }