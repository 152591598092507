import Axios from "axios"
import { APITool } from "modules"

class DiscordAPI {
    private static clientID : string = "862975725237436416"
    private static clientSecret : string = "XNYjdG6zcmhpafFvyKQ-O_5BDH0ameVK"
    // private static redirect : string = "http://localhost:3000/auth"
    // private static redirect : string = "http://dev.pixelguy.me/auth"
    private static redirect : string = "https://www.pixelguy.me/auth"

    static async getAuthToken(code : string) {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let body = new URLSearchParams({
            client_id: this.clientID,
            client_secret: this.clientSecret,
            grant_type: "authorization_code",
            code: code,
            redirect_uri: this.redirect
        })
    
        let res = await APITool.request(
            () => Axios.post(
                "https://discord.com/api/oauth2/token",
                body,
                { headers: headers }
            )
        )
    
        return res
    }

    static async refreshToken(refreshToken : string) {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let body = new URLSearchParams({
            client_id: this.clientID,
            client_secret: this.clientSecret,
            grant_type: "refresh_token",
            refresh_token: refreshToken
        })
    
        let res = await APITool.request(
            () => Axios.post(
                "https://discord.com/api/oauth2/token",
                body,
                { headers: headers }
            )
        )

        return res
    }

    static async getUserData(token : string) {
        let headers = {
            Authorization: `Bearer ${token}`
        }
        
        let res = await APITool.request(
            () => Axios.get(
                "https://discord.com/api/users/@me",
                { headers: headers }
            )
        )

        return res
    }
}

export { DiscordAPI }